'use strict';

const $ = require('jquery');
//const _ = require('underscore');
//const moment = require('moment');
//const dragula = require('dragula');
//const alertify = require('alertifyjs');
//const fancybox = require('fancybox')($);
//const slick = require('slick-carousel');
//const highlight = require('jquery-highlight');
import selectOrDie from'../plugins/selectordie';
const parsley = require('parsleyjs');

$('select').selectOrDie();

//==================================================================
// Make some magic
//==================================================================

let $menuRound = $('.menu-round');
let $menuItem = $('.menu-round__button');
let $menuNav = $('.menu-straight__item');
let $splashCenter = $('.splash-center');

let menuDegrees = [0, 90, 0, -90, -180];
let itemDegrees = [0, -90, 0, 90, 180];

function goToMenu(integer) {
    $menuRound.css({transform: 'rotate(' + menuDegrees[integer] + 'deg)'});
    $menuNav.removeClass('active');
    $menuItem.removeClass('active').css({transform: 'rotate(' + itemDegrees[integer] + 'deg)'});
    $splashCenter.css({transform: 'rotate(' + itemDegrees[integer] + 'deg)'});
    $('[data-pos=' + integer + ']').addClass('active');
}

$menuItem.on('click', function (e) {
    e.preventDefault();
    goToMenu(parseInt($(this).attr('data-pos')));
});

goToMenu(1);


let $kiddies = $('.has-kiddies');

$kiddies.on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('expanded')) {
        $('.expanded').removeClass('expanded');
    } else {
        $('.expanded').removeClass('expanded');
        $(this).addClass('expanded');
    }
});


//==================================================================
// Search Toggle
//==================================================================

let $search = $('header .searchForm span');

$search.on('click', function (e) {
    e.preventDefault();
    $(this).parent().toggleClass('active');
    $(this).parent().find('.text').val('').focus();
});


//==================================================================
// Navburger
//==================================================================

let $navburger = $('.navburger');

$navburger.on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('mobile-nav');
    $('.off-canvas').css({'min-height': $('.layout').height() + 'px'})
});

//==================================================================
// Kiddies party
//==================================================================

let $kids = $('.child-indicator');

$kids.on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('has-kiddies2')) {
        $(this).parent().parent().toggleClass('section');
    } else {
        $(this).parent().toggleClass('section');
    }
});

//==================================================================
// Errrror
//==================================================================


const $footerForm = $('form');

if ($footerForm.length > 0) {
    $footerForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.middleColumn');
        }
    }).on('form:submit', function () {
    });
}